import React, { PureComponent } from "react";
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from "recharts";

const AtsPieChart = (props: any) => {
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central" fontSize={10}>
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    // <ResponsiveContainer width="100%" height="100%">
    <PieChart width={350} height={240}>
      <Pie data={props.data} dataKey="population" cx="50%" cy="50%" outerRadius={80} fill="#8884d8" labelLine={false} label={renderCustomizedLabel}>
        {props.data.map((entry: any, index: any) => {
          return <Cell key={index} fill={entry.color} />;
        })}
      </Pie>
      <Legend layout="vertical" verticalAlign="middle" align="right" />
    </PieChart>
    // </ResponsiveContainer>
  );
};

export default AtsPieChart;
