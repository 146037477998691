import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Box, Center, color, DefaultChakraTheme, Flex, Heading, Icon, Image, Text } from "@chakra-ui/react";
import { IoPeopleOutline, IoRoseOutline, IoBusinessOutline, IoPersonCircleOutline } from "react-icons/io5";
import useAxios from "axios-hooks";
import { theme } from "@chakra-ui/theme";

import TabelRekap from "./TabelRekap";
import env from "react-dotenv";
import { useState } from "react";
import AtsPieChart from "./AtsPieChart";
import { defaultConfig, WilayahContext } from "./WilayahContext";

const App = () => {
  // Context Stuff
  const [config, setConfig] = useState(defaultConfig);
  const value = { config, setConfig };

  // Get default chackra colors
  const { colors } = theme;
  const [dbData, setDbData] = React.useState({
    jumlahAus: 210,
    jumlahOp: 13,
    jumlahDesa: 15,
    jumlahKec: 3,
    alasan: [
      {
        name: "Miskin",
        population: 215,
        color: colors.orange["400"],
        legendFontColor: "#888",
        legendFontSize: 12,
      },
      {
        name: "Bekerja",
        population: 119,
        color: colors.yellow["400"],
        legendFontColor: "#888",
        legendFontSize: 12,
      },
      {
        name: "Menikah",
        population: 358,
        color: colors.green["400"],
        legendFontColor: "#888",
        legendFontSize: 12,
      },
      {
        name: "Tidak ingin",
        population: 280,
        color: colors.blue["400"],
        legendFontColor: "#888",
        legendFontSize: 12,
      },
      {
        name: "Kond. Sosial",
        population: 227,
        color: colors.facebook["400"],
        legendFontColor: "#888",
        legendFontSize: 12,
      },
    ],
    usia: [
      {
        name: "7-12",
        population: 21,
        color: colors.red["400"],
        legendFontColor: "#888",
        legendFontSize: 12,
      },
      {
        name: "13-15",
        population: 11,
        color: colors.blue["900"],
        legendFontColor: "#888",
        legendFontSize: 12,
      },
      {
        name: "16-18",
        population: 8,
        color: colors.telegram["500"],
        legendFontColor: "#888",
        legendFontSize: 12,
      },
    ],
    gender: [
      {
        name: "L",
        population: 21,
        color: colors.green["400"],
        legendFontColor: "#888",
        legendFontSize: 12,
      },
      {
        name: "P",
        population: 11,
        color: colors.red["400"],
        legendFontColor: "#888",
        legendFontSize: 12,
      },
    ],
  });

  const [{ data, loading, error }, refetch] = useAxios(`${env.API_URL}/api/dashboard`);

  React.useEffect(() => {
    if (data) {
      //console.log(data);

      var alasanArr: any = [];
      data.alasan.map((alasan: any, key: number) => {
        var color = "";
        switch (alasan.alasanId) {
          case 1:
            color = colors.red["200"];
            break;
          case 2:
            color = colors.red["400"];
            break;
          case 3:
            color = colors.orange["300"];
            break;
          case 4:
            color = colors.yellow["300"];
            break;
          case 5:
            color = colors.green["300"];
            break;
          case 6:
            color = colors.teal["400"];
            break;
          case 7:
            color = colors.blue["400"];
            break;
          case 8:
            color = colors.cyan["400"];
            break;
          case 9:
            color = colors.purple["400"];
            break;
          case 10:
            color = colors.pink["400"];
            break;
          case 11:
            color = colors.gray["200"];
            break;
          case 12:
            color = colors.gray["400"];
            break;
          case 13:
            color = colors.gray["600"];
            break;
        }
        alasanArr.push({
          name: alasan.namaAlasan,
          population: alasan.jumlahAlasan,
          color: color,
          legendFontColor: "#888",
          legendFontSize: 12,
        });
      });

      var usiaArr: any = [];
      data.usia.map((usia: any, key: number) => {
        var color = "";
        switch (usia.usia) {
          case "7-12":
            color = colors.red["400"];
            break;
          case "13-15":
            color = colors.blue["800"];
            break;
          case "16-18":
            color = colors.telegram["600"];
            break;
        }

        usiaArr.push({
          name: usia.usia,
          population: usia.jumlahPerUsia,
          color: color,
          legendFontColor: "#888",
          legendFontSize: 12,
        });
      });

      var genderArr: any = [];
      data.gender.map((gender: any, key: number) => {
        var color = "";
        switch (gender.gender) {
          case "L":
            color = colors.green["400"];
            break;
          case "P":
            color = colors.red["400"];
            break;
        }

        genderArr.push({
          name: gender.gender,
          population: gender.jumlahPerGender,
          color: color,
          legendFontColor: "#888",
          legendFontSize: 12,
        });
      });

      setDbData({ ...data, alasan: alasanArr, usia: usiaArr, gender: genderArr });
    }
  }, [data]);

  return (
    <WilayahContext.Provider value={value}>
      <Box className="ats-header" height={140}>
        <Flex flexDirection="row" justifyContent="space-between" alignItems="center" pl={10} pr={10} pt={5}>
          <Box>
            <Heading color="white">SIPANTAS</Heading>
            <Text color="white" size="md">
              Sistem Pendataan Anak Tidak Sekolah - Kab. Bandung
            </Text>
            {/* <Text size="">Kab. Bandung</Text> */}
          </Box>
          <Image src="/logo/logo-kab-bandung.png" maxHeight={100} />
        </Flex>
        <Center>
          <Flex flexDirection="column" flex={1} maxWidth="1377" mt={5}>
            <Flex flexDirection="row" justifyContent="space-between" alignItems="center" pl={10} pr={10} pt={5}>
              <Box flex={1} pt={3} pl={5} pb={3} bg="cyan.400" rounded="md" maxW="xs" ml={5} boxShadow="lg">
                <Flex flexDirection="row" alignItems="center">
                  <Icon as={IoPeopleOutline} color="white" boxSize="10" />
                  <Flex flexDirection="column" alignItems="flex-start" ml={1}>
                    <Heading color="white" fontSize={"3xl"}>
                      {dbData.jumlahAus}
                    </Heading>
                    <Text color="white" fontSize="xxs">
                      ATS Terdata
                    </Text>
                  </Flex>
                </Flex>
              </Box>
              <Box flex={1} pt={3} pl={5} pb={3} bg="pink.400" rounded="md" maxW="xs" ml={5} boxShadow="lg">
                <Flex flexDirection="row" alignItems="center">
                  <Icon as={IoRoseOutline} color="white" boxSize="10" />
                  <Flex flexDirection="column" alignItems="flex-start" ml={1}>
                    <Heading color="white" fontSize={"3xl"}>
                      {dbData.jumlahDesa}
                    </Heading>
                    <Text color="white" fontSize="xxs">
                      Desa Terdata
                    </Text>
                  </Flex>
                </Flex>
              </Box>
              <Box flex={1} pt={3} pl={5} pb={3} bg="green.400" rounded="md" maxW="xs" ml={5} boxShadow="lg">
                <Flex flexDirection="row" alignItems="center">
                  <Icon as={IoBusinessOutline} color="white" boxSize="10" />
                  <Flex flexDirection="column" alignItems="flex-start" ml={1}>
                    <Heading color="white" fontSize={"3xl"}>
                      {dbData.jumlahKec}
                    </Heading>
                    <Text color="white" fontSize="xxs">
                      Kecamatan
                    </Text>
                  </Flex>
                </Flex>
              </Box>
              <Box flex={1} pt={3} pl={5} pb={3} bg="orange.400" rounded="md" maxW="xs" ml={5} boxShadow="lg">
                <Flex flexDirection="row" alignItems="center">
                  <Icon as={IoPersonCircleOutline} color="white" boxSize="10" />
                  <Flex flexDirection="column" alignItems="flex-start" ml={1}>
                    <Heading color="white" fontSize={"3xl"}>
                      {dbData.jumlahOp}
                    </Heading>
                    <Text color="white" fontSize="xxs">
                      Operator Desa
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            </Flex>
            <Flex flexDirection="row" justifyContent="space-between" alignItems="center" pl={10} pr={10} pt={5}>
              <Box flex={1} pt={3} pl={5} pb={3} bg="white" rounded="md" ml={5} borderWidth={1} borderColor="gray.300" boxShadow="lg">
                <Flex flexDirection="column">
                  <Text>Alasan Tidak Sekolah ATS</Text>
                  <AtsPieChart data={dbData.alasan} />
                </Flex>
              </Box>
              <Box flex={1} pt={3} pl={5} pb={3} bg="white" rounded="md" ml={5} borderWidth={1} borderColor="gray.300" boxShadow="lg">
                <Flex flexDirection="column">
                  <Text>Usia ATS</Text>
                  <AtsPieChart data={dbData.usia} />
                </Flex>
              </Box>
              <Box flex={1} pt={3} pl={5} pb={3} bg="white" rounded="md" ml={5} borderWidth={1} borderColor="gray.300" boxShadow="lg">
                <Flex flexDirection="column">
                  <Text>Gender ATS</Text>
                  <AtsPieChart data={dbData.gender} />
                </Flex>
              </Box>
            </Flex>
            <Flex flexDirection="row" justifyContent="space-between" alignItems="center" pl={10} pr={10} pt={5}>
              <Box flex={1} bg="white" rounded="md" ml={5} borderWidth={1} borderColor="gray.300" boxShadow="lg">
                <TabelRekap />
              </Box>
            </Flex>
          </Flex>
        </Center>
      </Box>
    </WilayahContext.Provider>
  );
};

export default App;
