import React, { useContext } from "react";
import {
  Radio,
  RadioGroup,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Heading,
  Text,
  Box,
  Divider,
  Spinner,
  Container,
  Stack,
  HStack,
  Button,
  IconButton,
  Spacer,
  Flex,
} from "@chakra-ui/react";

import env from "react-dotenv";
import useAxios from "axios-hooks";
import { defaultConfig, WilayahContext } from "./WilayahContext";
import { AiOutlineFileExcel } from "react-icons/ai";
import { BsArrow90DegUp } from "react-icons/bs";

const TabelRekap = () => {
  // Context stuff
  const { config, setConfig } = useContext(WilayahContext);

  const updateDataSet = (value: any) => {
    setConfig({ ...config, data_set: value });
  };
  const updateWilayah = (kode_wilayah: string, nama: string, level: string) => {
    setConfig({ ...config, kode_wilayah: kode_wilayah.trim(), level: level, nama: nama });
  };

  // console.log(config);

  const [{ data, loading, error }, refetch] = useAxios({
    url: `${env.API_URL}/api/tabular`,
    params: {
      kode_wilayah: config.kode_wilayah,
      nama: config.nama,
      level: config.level,
      data_set: config.data_set,
    },
  });

  const [tableHeaders, setTableHeaders] = React.useState<any>([]);

  React.useEffect(() => {
    if (data) {
      var tHeaders = [];
      // console.log(data);
      for (const [columnName, columnValue] of Object.entries(data[0])) {
        tHeaders.push(columnName);
      }
      setTableHeaders(tHeaders);
    }
  }, [data]);

  // return <Text>Anu</Text>;

  return (
    <Box>
      <HStack pt={5} pr={5} pl={5} pb={3} justifyContent="space-between">
        <Text>
          Rekap {config.data_set.charAt(0).toUpperCase() + config.data_set.slice(1)} ATS Per-
          {config.level === "kabkota" ? "Kecamatan" : "Desa"}{" "}
          {config.level === "kecamatan" ? (
            <IconButton
              colorScheme="teal"
              aria-label="Back"
              size="xs"
              icon={
                <BsArrow90DegUp
                  onClick={() => {
                    setConfig(defaultConfig);
                  }}
                />
              }
            />
          ) : (
            ""
          )}
        </Text>
        <RadioGroup onChange={updateDataSet} value={config.data_set}>
          <Stack direction="row">
            <Radio value="alasan">Alasan</Radio>
            <Radio value="usia">Usia</Radio>
            <Radio value="gender">Gender</Radio>
          </Stack>
        </RadioGroup>
      </HStack>
      <Divider />
      {data ? (
        <Container maxW="container.xl" overflow="scroll">
          <Table>
            <Thead>
              <Tr bg="gray.100">
                {tableHeaders.map((header: string, key: number) => (
                  <Th height={20}>{header}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {data.map((row: any, key: number) => {
                return (
                  <Tr
                    key={key}
                    _hover={{ bg: "blue.100", cursor: "pointer" }}
                    onClick={() => {
                      if (row.kode_kecamatan) {
                        updateWilayah(row.kode_kecamatan, row.nama_kecamatan, "kecamatan");
                        // } else if (row.kode_kelurahan) {
                        //   updateWilayah(row.kode_kelurahan, row.nama_kelurahan, "kelurahan");
                      } else {
                        return;
                      }
                    }}
                  >
                    {tableHeaders.map((header: string, col: number) => (
                      <Td>
                        <Text fontSize="13">{row[tableHeaders[col]]}</Text>
                      </Td>
                    ))}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Container>
      ) : (
        <Spinner />
      )}
      <Divider />
      <Flex p={5}>
        <Spacer />
        <Box>
          <Button
            rightIcon={<AiOutlineFileExcel fontSize="24" />}
            colorScheme="teal"
            variant="solid"
            size="md"
            onClick={() => {
              window.open(`${env.API_URL}/api/excel?data_set=${config.data_set}&kode_wilayah=${config.kode_wilayah}&nama=${config.nama}`, "_blank");
            }}
          >
            Unduh Excel
          </Button>
        </Box>
      </Flex>
    </Box>
  );
};

export default TabelRekap;
